<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Recent Applications</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        <can do="create" on="all-state-applications">
          <b-button :disabled="loading" v-b-tooltip title="Create Application" variant="link" size="sm" class="py-0" :to="{ name: 'all-state-application-create'}">
            <font-awesome-icon icon="fas fa-plus"/>
          </b-button>
        </can>
      </b-card-text>
    </b-card-header>

    <b-table :items="filteredItems"
             :busy="loading"
             :per-page="5"
             :fields="fields"
             responsive
             show-empty empty-text="No application records found"
             sort-by="createdAt" sort-desc>

      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>

      <!-- Student -->
      <template #cell(student)="data">
        <div class="d-flex align-items-center">
          <b-avatar rounded size="32" variant="light-primary" class="mr-1">
            <font-awesome-icon icon="fa-solid fa-graduation-cap"/>
          </b-avatar>
          <div>
            <template>
              <div v-if="hasValue(data, 'item.student.name')" class="font-weight-bolder">
                {{ data.item.student.name.first }} {{ data.item.student.name.last }}
              </div>
              <div v-else class="font-weight-bolder text-danger">
                No Student
              </div>
            </template>
            <template>
              <div v-if="hasValue(data, 'item.student.school.name')" class="font-small-2 text-muted">
                {{ data.item.student.school.name.legal }}
              </div>
              <div v-else class="font-small-2 text-danger">
                No School
              </div>
            </template>
          </div>
        </div>
      </template>

      <!-- Festival -->
      <template #cell(festival)="data">
        <template v-if="hasValue(data, 'item.festival')">
          {{ data.item.festival.name }}
        </template>
        <template v-else>
          <span class="text-danger">No Festival</span>
        </template>
      </template>

      <!-- Instrument -->
      <template #cell(instrument)="data">
        <template v-if="hasValue(data, 'item.instrument')">
          {{ data.item.instrument.name }}
        </template>
        <template v-else>
          <span class="text-danger">No Instrument</span>
        </template>
      </template>

      <!-- Options -->
      <template #cell(row-options)="data">
        <table-row-options v-if="data.item.student" :index="data.index">
          <b-dropdown-item v-if="$can('read', 'all-state-application')"
                          :to="{ name: 'all-state-application', params: { id: data.item.id } }"
                           class="table-row-option-view">
            <font-awesome-icon icon="fas fa-clipboard" />
            <span class="align-middle ml-50">View Application</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="$can('read', 'all-state-student')"
                           :to="{ name: 'all-state-student', params: { id: data.item.student.id } }"
                           class="table-row-option-view">
            <font-awesome-icon icon="fas fa-graduation-cap" />
            <span class="align-middle ml-50">View Student</span>
          </b-dropdown-item>
        </table-row-options>
      </template>
    </b-table>

  </b-card>
</template>

<script>
import TableRowOptions from '@/components/TableRowOptions.vue';
import lodashMixin from '@/mixins/lodash.mixin';

export default {
  name: 'StudentsTable',
  components: {TableRowOptions},
  mixins: [lodashMixin],
  props: {
    user: {
      type: Object,
      required: true
    },
    festivals: {
      type: Array,
      required: true
    },
    forms: {
      type: Array,
      required: true
    },
    instruments: {
      type: Array,
      required: true
    },
    applications: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      items: [],
      fields: [
        { key: 'student', label: 'Student' },
        { key: 'festival', label: 'Festival' },
        { key: 'instrument', label: 'Instrument' },
        { key: 'createdAt', label: 'Submitted', formatter: this.date},
      ]
    }
  },
  computed: {
    filteredItems() {
      if(this.user.groups.includes('Teacher')) {
        return this.items
      }
      if(this.user.groups.includes('ZoneRep')) {
        const festivalIds = [...new Set(this.festivals?.filter(festival => this.user.zoneIds.includes(festival.festivalZoneId)).map(festival => festival.id))];
        return this.items?.filter(app => festivalIds.includes(app.applicationFestivalId))
      }
      return this.items
    }
  },
  watch: {
    loading(value) {
      this.items = this.applications
    }
  },
  mounted() {
    if(this.$can('read', 'all-state-application') || this.$can('read', 'all-state-student')) {
      this.fields.push({ key: 'row-options', label: '', tdClass: ['align-middle', 'table-row-options'] },)
    }
  },
  methods: {
    date(value) {
      return new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(value))
    }
  }
}
</script>

<style scoped>

</style>
