<template>
  <b-card body-class="h-100">
    <b-card-header class="p-0">
      <b-card-title class="mb-sm-0 mb-1">
        {{ $can('read', 'dashboard', 'selections') ? 'Scored & Selected' : 'Scored Applications' }}
      </b-card-title>
    </b-card-header>
    <b-overlay :show="loading" :opacity="1" variant="white" spinner-small>
      <vue-apex-charts v-if="!loading"
                       type="radialBar" height="400"
                       :options="options"
                       :series="series"
      />
    </b-overlay>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'ScoredAndSelectedApplicationsCard',
  components: {
    VueApexCharts
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    applications: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      series: [],
      options: {
        colors: ['#212969', '#F1C243', '#28c76f'],
        plotOptions: {
          radialBar: {
            size: 185,
            hollow: {
              size: '25%',
            },
            track: {
              margin: 15,
            },
            dataLabels: {
              name: {
                fontSize: '2rem',
                fontFamily: 'Montserrat',
              },
              value: {
                fontSize: '1rem',
                fontFamily: 'Montserrat',
              },
              total: {
                show: true,
                fontSize: '1rem',
                label: 'Applications',
                formatter() {
                  return '100%'
                },
              },
            },
          },
        },
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'center',
        },
        stroke: {
          lineCap: 'round',
        },
        labels: ['Applications', 'Scored'],
      },
    }
  },
  watch: {
    applications() {
      this.setChartData()
    }
  },
  mounted() {
    if(this.$can('read', 'dashboard', 'listSelections')) {
      this.options.labels.push('Selected')
    }
    this.setChartData()
  },
  methods: {
    setChartData() {
      this.series = []
      const totalApplications = this.applications.length
      const totalScored = this.applications.filter(item => item.ranking.score > 0 && item.ranking.local > 0 ).length
      this.series = [100, ((totalScored / totalApplications) * 100).toFixed(2)]

      if(this.$can('read', 'dashboard', 'listSelections')) {
        const totalSelected = this.applications.filter(item => item.applicationSelectionId).length;
        this.series.push(((totalSelected / totalApplications) * 100).toFixed(2))
      }
    }
  }
}
</script>

<style scoped>

</style>
