<template>
  <b-card class="card-statistics">
    <b-media no-body>
      <b-media-aside class="mr-2">
        <b-avatar size="48" variant="light-warning">
          <feather-icon size="24" icon="MusicIcon"/>
        </b-avatar>
      </b-media-aside>
      <b-media-body class="my-auto">
        <b-spinner v-if="loading" small class="align-middle"></b-spinner>
        <h4 v-else class="font-weight-bolder mb-0">
          {{ value }} / {{ maxValue }}
        </h4>
        <b-card-text class="font-small-3 mb-0">
          Instruments
        </b-card-text>
      </b-media-body>
    </b-media>
  </b-card>
</template>

<script>
export default {
  name: 'StatCard',
  props: {
    user: {
      type: Object,
      required: true
    },
    instruments: {
      type: Array,
      required: true
    },
    festivals: {
      type: Array,
      required: true
    },
    selections: {
      type: Array,
      required: true
    },
    applications: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
  },
  computed: {
    value() {
      if(this.user.groups.includes('NYSSMA_Committee') || this.user.groups.includes('NYSSMA_Chair')) {
        const selections = this.selections.filter(selection => this.user.ensembleIds.includes(selection.selectionEnsembleId) )
        const applications = this.applications.filter(application => selections.some(selection => application.applicationSelectionId === selection.id))
        const uniqueAppInstruments = [...new Set(applications.map(item => item.applicationInstrumentId))];
        return this.instruments?.filter(instrument => uniqueAppInstruments.includes(instrument.id)).length || 0
      }
      if(this.user.groups.includes('Teacher')) {
        const uniqueAppInstruments = [...new Set(this.applications.map(item => item.applicationInstrumentId))];
        return this.instruments?.filter(instrument => uniqueAppInstruments.includes(instrument.id)).length || 0
      }
      if(this.user.groups.includes('ZoneRep')) {
        const festivalIds = this.festivals?.filter(festival => this.user.zoneIds.includes(festival.festivalZoneId)).map(festival => festival.id)
        const apps = this.applications.filter(app => festivalIds.includes(app.applicationFestivalId))
        const uniqueAppInstruments = [...new Set(apps.map(item => item.applicationInstrumentId))];
        return this.instruments?.filter(instrument => uniqueAppInstruments.includes(instrument.id)).length || 0
      }

      const uniqueAppInstruments = [...new Set(this.applications.map(item => item.applicationInstrumentId))];
      return this.instruments?.filter(instrument => uniqueAppInstruments.includes(instrument.id)).length || 0
    },
    maxValue() {
      return this.instruments?.length || 0
    }
  }
}
</script>

<style scoped>

</style>
