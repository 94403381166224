<template>
  <b-card body-class="h-100">
    <b-card-header class="p-0">
      <b-card-title class="mb-sm-0 mb-1">
        Scored & Selected by
      </b-card-title>
      <div>
        <b-button-toolbar>
          <b-button-group>
            <b-dropdown right variant="transparent" size="sm" :text="by">
              <b-dropdown-item-button button-class="w-100" @click="by = 'Form'">Form</b-dropdown-item-button>
              <b-dropdown-item-button button-class="w-100" @click="by = 'Instrument'">Instrument</b-dropdown-item-button>
              <b-dropdown-item-button button-class="w-100" @click="by = 'Festival'">Festival</b-dropdown-item-button>
              <b-dropdown-item-button button-class="w-100" @click="by = 'Zone'">Zone</b-dropdown-item-button>
            </b-dropdown>
            <b-dropdown v-if="by === 'Festival'" right variant="transparent" size="sm" :text="festival.zone ? festival.zone.name : 'Zone'" class="ml-50">
              <b-dropdown-item-button v-for="zone in computedZones" :key="zone.id"
                                      button-class="w-100" @click="festival.zone = zone">
                {{ zone.name }}
              </b-dropdown-item-button>
              <b-dropdown-divider/>
              <b-dropdown-item-button button-class="w-100" @click="festival.zone = null">
                Clear
              </b-dropdown-item-button>
            </b-dropdown>
            <b-button variant="transparent" size="sm" @click="showModal = true">
              <b-icon icon="arrows-angle-expand" />
            </b-button>

            <b-modal v-model="showModal" size="xl" centered hide-footer >
              <template v-slot:modal-title>
                Application Submission Time
              </template>
              <vue-apex-charts v-if="!loading && by === 'Instrument'"
                               type="bar" height="600"
                               :options="options"
                               :series="instrumentSeries"
              />
              <vue-apex-charts v-if="!loading && by === 'Form'"
                               type="bar" height="600"
                               :options="options"
                               :series="formSeries"
              />
              <vue-apex-charts v-if="!loading && by === 'Festival'"
                               type="bar"
                               :options="options"
                               :series="festivalSeries"
              />
              <vue-apex-charts v-if="!loading && by === 'Zone'"
                               type="bar" height="600"
                               :options="options"
                               :series="zoneSeries"
              />
            </b-modal>



          </b-button-group>
        </b-button-toolbar>
      </div>

    </b-card-header>
    <b-overlay :show="loading" :opacity="1" variant="white" spinner-small>
      <vue-apex-charts v-if="!loading && by === 'Instrument'"
                       type="bar" height="600"
                       :options="options"
                       :series="instrumentSeries"
      />
      <vue-apex-charts v-if="!loading && by === 'Form'"
                       type="bar" height="600"
                       :options="options"
                       :series="formSeries"
      />
      <vue-apex-charts v-if="!loading && by === 'Festival'"
                       type="bar"
                       :options="options"
                       :series="festivalSeries"
      />
      <vue-apex-charts v-if="!loading && by === 'Zone'"
                       type="bar" height="600"
                       :options="options"
                       :series="zoneSeries"
      />
    </b-overlay>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'ScoredAndSelectedApplicationsCard',
  components: {
    VueApexCharts
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    festivals: {
      type: Array,
      required: true
    },
    instruments: {
      type: Array,
      required: true
    },
    zones: {
      type: Array,
      required: true
    },
    applications: {
      type: Array,
      required: true
    },
    selections: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      by: 'Instrument',
      showModal: false,
      series: [],
      form: {
        series: []
      },
      festival: {
        series: [],
        zone: null
      },
      zone: {
        series: []
      },
      options: {
        colors: ['#212969', '#F1C243', '#28c76f'],
        chart: {
          stacked: true,
          //stackType: '100%',
          width: '100%',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        }
      }
    }
  },
  computed: {
    instrumentSeries() {
      const groupByKeys = (data, keys) => keys.map(key => Object.values(data.reduce((result, obj) => {
        const objKey = obj[key]
        result[objKey] = result[objKey] || { key: key, x: objKey, y: 0 };
        result[objKey].y += 1;
        return result
      }, {})))

      let appGroupData = []
      let scoredGroupData = []
      let selectedGroupData = []

      const nullInstrument = { id: 0, name: 'Unknown', slug: 'unknown'}

      if(this.applications.some(app => app.instrument)) {
        appGroupData = groupByKeys(this.applications.map(app => (app?.instrument || nullInstrument)), ['name'])[0].map(data => ({ x: data.x, y: data.y }))
        if(this.selections.length) {
          selectedGroupData = groupByKeys(this.selections.map(selection => this.applications.find(app => app.id === selection.selectionApplicationId)?.instrument || nullInstrument), ['name'])[0].map(data => ({ x: data.x, y: data.y }))
        }
      }
      if(this.applications.some(app => app.ranking)) {
        scoredGroupData = groupByKeys(this.applications.filter(app => app.ranking.score > 0 && app.ranking.local > 0).map(app => app?.instrument || nullInstrument), ['name'])[0].map(data => ({ x: data.x, y: data.y }))
      }

      const scoredDataFixed = []
      const selectedDataFixed = []
      appGroupData.forEach(appData => {
        const scored = scoredGroupData.find(data => data.x === appData.x)
        const selected = selectedGroupData.find(data => data.x === appData.x)

        if(scored) { scoredDataFixed.push(scored) }
        else { scoredDataFixed.push({ x: appData.x, y: 0}) }

        if(selected) { selectedDataFixed.push(selected) }
        else { selectedDataFixed.push({ x: appData.x, y: 0}) }
      })

      const series = []
      series.push({ name: 'Applications', data: appGroupData.sort((a, b) => a.x.localeCompare(b.x)) })
      series.push({ name: 'Scored', data: scoredDataFixed.sort((a, b) => a.x.localeCompare(b.x)) })

      if(this.$can('read', 'dashboard', 'listSelections')) {
        series.push({ name: 'Selected', data: selectedDataFixed.sort((a, b) => a.x.localeCompare(b.x))})
      }
      return series
    },
    formSeries() {
      const groupByKeys = (data, keys) => keys.map(key => Object.values(data.reduce((result, obj) => {
        const objKey = obj[key]
        result[objKey] = result[objKey] || { key: key, x: objKey, y: 0 };
        result[objKey].y += 1;
        return result
      }, {})))

      let appGroupData = []
      let scoredGroupData = []
      let selectedGroupData = []

      const nullForm = { id: 0, name: 'Unknown', slug: 'unknown'}

      if(this.applications.some(app => app.form)) {
        appGroupData = groupByKeys(this.applications.map(app => app?.form || nullForm), ['name'])[0].map(data => ({ x: data.x, y: data.y }))
        if(this.selections.length) {
          selectedGroupData = groupByKeys(this.selections.map(selection => this.applications.find(app => app.id === selection.selectionApplicationId)?.form || nullForm), ['name'])[0].map(data => ({ x: data.x, y: data.y }))
        }
      }
      if(this.applications.some(app => app.ranking)) {
        scoredGroupData = groupByKeys(this.applications.filter(app => app.ranking.score > 0 && app.ranking.local > 0).map(app => app?.form || nullForm), ['name'])[0].map(data => ({ x: data.x, y: data.y }))
      }

      const scoredDataFixed = []
      const selectedDataFixed = []
      appGroupData.forEach(appData => {
        const scored = scoredGroupData.find(data => data.x === appData.x)
        const selected = selectedGroupData.find(data => data.x === appData.x)

        if(scored) { scoredDataFixed.push(scored) }
        else { scoredDataFixed.push({ x: appData.x, y: 0}) }

        if(selected) { selectedDataFixed.push(selected) }
        else { selectedDataFixed.push({ x: appData.x, y: 0}) }
      })

      const series = []
      series.push({ name: 'Applications', data: appGroupData.sort((a, b) => a.x.localeCompare(b.x)) })
      series.push({ name: 'Scored', data: scoredDataFixed.sort((a, b) => a.x.localeCompare(b.x)) })

      if(this.$can('read', 'dashboard', 'listSelections')) {
        series.push({ name: 'Selected', data: selectedDataFixed.sort((a, b) => a.x.localeCompare(b.x))})
      }
      return series
    },
    festivalSeries() {
      const groupByKeys = (data, keys) => keys.map(key => Object.values(data.reduce((result, obj) => {
        const objKey = obj[key]
        result[objKey] = result[objKey] || { key: key, x: objKey, y: 0 };
        result[objKey].y += 1;
        return result
      }, {})))

      const applications = this.applications.filter(app => {
        if(this.festival.zone !== null) {
          return app?.festival?.festivalZoneId === this.festival?.zone?.id
        }
        return true
      })

      let appGroupData = []
      let scoredGroupData = []
      let selectedGroupData = []

      const nullFestival = { id: 'unknown', name: 'Unknown', slug: 'unknown'}

      if(applications.some(app => app.festival)) {
        appGroupData = groupByKeys(applications.map(app => app?.festival || nullFestival), ['id'])[0].map(data => ({ x: data.x, y: data.y }))
        if(this.selections.length) {
          selectedGroupData = groupByKeys(this.selections.map(selection => this.applications.find(app => app.id === selection.selectionApplicationId)?.festival || nullFestival), ['id'])[0].map(data => ({ x: data.x, y: data.y }))
        }
      }
      if(applications.some(app => app.ranking)) {
        scoredGroupData = groupByKeys(applications.filter(app => app.ranking.score > 0 && app.ranking.local > 0).map(app => app?.festival || nullFestival), ['id'])[0].map(data => ({ x: data.x, y: data.y }))
      }

      const appDataFixed = []
      const scoredDataFixed = []
      const selectedDataFixed = []
      appGroupData.forEach(appData => {
        const scored = scoredGroupData.find(data => data.x === appData.x)
        const selected = selectedGroupData.find(data => data.x === appData.x)
        const festivalName = this.festivals.find(festival => festival.id === appData.x)?.name || 'Unknown'

        appDataFixed.push({ x: festivalName, y: appData.y })

        if(scored) { scoredDataFixed.push({ x: festivalName, y: scored.y }) }
        else { scoredDataFixed.push({ x: festivalName, y: 0}) }

        if(selected) { selectedDataFixed.push({ x: festivalName, y: selected.y }) }
        else { selectedDataFixed.push({ x: festivalName, y: 0}) }
      })

      const series = []
      series.push({ name: 'Applications', data: appDataFixed.sort((a, b) => a.x.localeCompare(b.x, 'en', { numeric: true })) })
      series.push({ name: 'Scored', data: scoredDataFixed.sort((a, b) => a.x.localeCompare(b.x, 'en', { numeric: true })) })

      if(this.$can('read', 'dashboard', 'listSelections')) {
        series.push({ name: 'Selected', data: selectedDataFixed.sort((a, b) => a.x.localeCompare(b.x, 'en', { numeric: true }))})
      }
      return series
    },
    zoneSeries() {
      const groupByKeys = (data, keys) => keys.map(key => Object.values(data.reduce((result, obj) => {
        const objKey = obj[key]
        result[objKey] = result[objKey] || { key: key, x: objKey, y: 0 };
        result[objKey].y += 1;
        return result
      }, {})))

      let appGroupData = []
      let scoredGroupData = []
      let selectedGroupData = []

      const nullFestival = { id: 'unknown', name: 'Unknown', slug: 'unknown', festivalZoneId: 'unknown' }

      if(this.applications.some(app => app.festival)) {
        appGroupData = groupByKeys(this.applications.map(app => app?.festival || nullFestival), ['festivalZoneId'])[0].map(data => ({ x: data.x, y: data.y }))
        if(this.selections.length) {
          selectedGroupData = groupByKeys(this.selections.map(selection => this.applications.find(app => app.id === selection.selectionApplicationId)?.festival || nullFestival), ['festivalZoneId'])[0].map(data => ({ x: data.x, y: data.y }))
        }
      }
      if(this.applications.some(app => app.ranking)) {
        scoredGroupData = groupByKeys(this.applications.filter(app => app.ranking.score > 0 && app.ranking.local > 0).map(app => app?.festival || nullFestival), ['festivalZoneId'])[0].map(data => ({ x: data.x, y: data.y }))
      }

      const appDataFixed = []
      const scoredDataFixed = []
      const selectedDataFixed = []
      appGroupData.forEach(appData => {
        const scored = scoredGroupData.find(data => data.x === appData.x)
        const selected = selectedGroupData.find(data => data.x === appData.x)
        const zoneName = this.zones.find(zone => zone.id === appData.x)?.name || 'Unknown'

        appDataFixed.push({ x: zoneName, y: appData.y })

        if(scored) { scoredDataFixed.push({ x: zoneName, y: scored.y }) }
        else { scoredDataFixed.push({ x: zoneName, y: 0}) }

        if(selected) { selectedDataFixed.push({ x: zoneName, y: selected.y }) }
        else { selectedDataFixed.push({ x: zoneName, y: 0}) }
      })

      const series = []
      series.push({ name: 'Applications', data: appDataFixed.sort((a, b) => a.x.localeCompare(b.x, 'en', { numeric: true })) })
      series.push({ name: 'Scored', data: scoredDataFixed.sort((a, b) => a.x.localeCompare(b.x, 'en', { numeric: true })) })

      if(this.$can('read', 'dashboard', 'listSelections')) {
        series.push({ name: 'Selected', data: selectedDataFixed.sort((a, b) => a.x.localeCompare(b.x, 'en', { numeric: true }))})
      }
      return series
    },
    computedZones() {
      return this.zones.slice().sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }))
    }
  },
}
</script>

<style scoped>

</style>
