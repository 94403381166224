<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>My Ensembles</b-card-title>
    </b-card-header>

    <b-table :items="filteredItems"
             :busy="loading"
             :fields="[
                     { key: 'name', label: 'Name' },
                     { key: 'instruments.items.length', label: 'Instruments' },
                     { key: 'selected', label: 'Selected' },
                     { key: 'accepted', label: 'Accepted' },
                     { key: 'declined', label: 'Declined' },
                   ]"
             show-empty empty-text="No ensembles found"
             sort-by="createdAt" sort-desc>

      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>

      <template #cell(student)="data">
        <div class="d-flex align-items-center">
          <b-avatar rounded size="32" variant="light-primary" class="mr-1">
            <font-awesome-icon icon="fa-solid fa-graduation-cap"/>
          </b-avatar>
          <div>
            <div class="font-weight-bolder">
              {{ data.item.student.name.first }} {{ data.item.student.name.last }}
            </div>
            <div class="font-small-2 text-muted">
              {{ data.item.student.school.name.legal }}
            </div>
          </div>
        </div>
      </template>
    </b-table>

  </b-card>
</template>

<script>
import TableRowOptions from '@/components/TableRowOptions.vue';

export default {
  name: 'RecentSelections',
  components: {TableRowOptions},
  props: {
    user: {
      type: Object,
      required: true
    },
    instruments: {
      type: Array,
      required: true
    },
    ensembles: {
      type: Array,
      required: true
    },
    selections: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      items: []
    }
  },
  computed: {
    filteredItems() {
      if(this.user.groups.includes('NYSSMA_Committee') || this.user.groups.includes('NYSSMA_Chair')) {
        return this.items.filter(ensemble => this.user.ensembleIds.includes(ensemble.id))
      }
      return this.items
    }
  },
  watch: {
    loading() {
      this.items = this.ensembles.map(ensemble => ({ ...ensemble,
            selected: this.selections.filter(selection => selection.selectionEnsembleId === ensemble.id).length,
            accepted: this.selections.filter(selection => ensemble.id === selection.selectionEnsembleId && selection.accepted === true).length,
            declined: this.selections.filter(selection => ensemble.id === selection.selectionEnsembleId && selection.accepted === false).length,
          }
      ))
    }
  },
  methods: {
    date(value) {
      return new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(value))
    }
  }
}
</script>

<style scoped>

</style>
