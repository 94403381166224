<template>
  <b-card class="card-statistics">
    <b-media no-body>
      <b-media-aside class="mr-2">
        <b-avatar size="48" variant="light-dark">
          <feather-icon size="24" icon="HeadphonesIcon"/>
        </b-avatar>
      </b-media-aside>
      <b-media-body class="my-auto">
        <b-spinner v-if="loading" small class="align-middle"></b-spinner>
        <h4 v-else class="font-weight-bolder mb-0">
          {{ value }} / {{ maxValue }}
        </h4>
        <b-card-text class="font-small-3 mb-0">
          Ensembles
        </b-card-text>
      </b-media-body>
    </b-media>
  </b-card>
</template>

<script>
export default {
  name: 'StatCard',
  props: {
    user: {
      type: Object,
      required: true
    },
    ensembles: {
      type: Array,
      required: true
    },
    applications: {
      type: Array,
      required: true
    },
    selections: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
  },
  computed: {
    value() {
      /*if(this.user.groups.includes('NYSSMA_Committee') || this.user.groups.includes('NYSSMA_Chair')) {
        const uniqueSelectionIds = [...new Set(this.applications.map(item => item.applicationSelectionId))];
        const uniqueEnsembleIds = [...new Set(this.selections.filter(selection => uniqueSelectionIds.includes(selection.id)).map(item => item.selectionEnsembleId))];
        return this.ensembles?.filter(ensemble => uniqueEnsembleIds.includes(ensemble.id)).length || 0
      }
      if(this.user.groups.includes('Teacher')) {
        const uniqueSelectionIds = [...new Set(this.applications.map(item => item.applicationSelectionId))];
        const uniqueEnsembleIds = [...new Set(this.selections.filter(selection => uniqueSelectionIds.includes(selection.id)).map(item => item.selectionEnsembleId))];
        return this.ensembles?.filter(ensemble => uniqueEnsembleIds.includes(ensemble.id)).length || 0
      }*/
      const uniqueSelectionIds = [...new Set(this.applications.map(item => item.applicationSelectionId))];
      const uniqueEnsembleIds = [...new Set(this.selections.filter(selection => uniqueSelectionIds.includes(selection.id)).map(item => item.selectionEnsembleId))];
      return this.ensembles?.filter(ensemble => uniqueEnsembleIds.includes(ensemble.id)).length || 0
    },
    maxValue() {
      if(this.user.groups.includes('NYSSMA_Committee') || this.user.groups.includes('NYSSMA_Chair')) {
        return this.ensembles?.filter(ensemble => this.user.ensembleIds.includes(ensemble.id)).length || 0
      }
      return this.ensembles?.length || 0
    }
  }
}
</script>

<style scoped>

</style>
