<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Recently Selected for All-State</b-card-title>
    </b-card-header>
    <b-table :items="filteredItems"
             :busy="loading"
             :primary-key="'id'"
             :per-page="5"
             :fields="fields"
             show-empty empty-text="No selected application records found"
             sort-by="createdAt" sort-desc>

      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>

      <template #cell(application.student)="data">
        <div class="d-flex align-items-center">
          <b-avatar rounded size="32" variant="light-primary" class="mr-1">
            <font-awesome-icon icon="fa-solid fa-graduation-cap"/>
          </b-avatar>
          <div>
            <template>
              <div v-if="hasValue(data, 'item.application.student.name')" class="font-weight-bolder">
                {{ data.item.application.student.name.first }} {{ data.item.application.student.name.last }}
              </div>
              <div v-else class="font-weight-bolder text-danger">
                No Student
              </div>
            </template>
            <template>
              <div v-if="hasValue(data, 'item.application.student.school.name')" class="font-small-2 text-muted">
                {{ data.item.application.student.school.name.legal }}
              </div>
              <div v-else class="font-small-2 text-danger">
                No School
              </div>
            </template>
          </div>
        </div>
      </template>

      <!-- Ensemble -->
      <template #cell(ensemble.name)="data">
        <template v-if="hasValue(data, 'item.ensemble')">
          {{ data.item.ensemble.name }}
        </template>
        <template v-else>
          <span class="text-danger">No Ensemble</span>
        </template>
      </template>

      <!-- Instrument -->
      <template #cell(application.instrument.name)="data">
        <template v-if="hasValue(data, 'item.application.instrument.name')">
          {{ data.item.application.instrument.name }}
        </template>
        <template v-else>
          <span class="text-danger">No Instrument</span>
        </template>
      </template>

      <!-- Part -->
      <template #cell(part)="data">
        <template v-if="data.item.ensemble && data.item.ensemble.hasParts">
          {{ data.item.part }}
        </template>
        <template v-else>
          N/A
        </template>
      </template>


      <template #cell(row-options)="data">
        <table-row-options :index="data.index">
          <b-dropdown-item v-if="$can('read', 'all-state-selection')"
                           :to="{ name: 'all-state-selection', params: { slug: hasValue(data, 'item.application.instrument') ? data.item.application.instrument.slug : 'no-instrument' } }"
                           class="table-row-option-view">
            <font-awesome-icon icon="fas fa-clipboard" />
            <span class="align-middle ml-50">View Selection</span>
          </b-dropdown-item>
          <b-dropdown-item v-if="$can('read', 'all-state-selection-acceptance')"
                           :to="{ name: 'all-state-selection-acceptance', params: { slug: hasValue(data, 'item.application.instrument') ? data.item.application.instrument.slug : 'no-instrument'} }"
                           class="table-row-option-view">
            <font-awesome-icon icon="fas fa-clipboard" />
            <span class="align-middle ml-50">View Acceptance</span>
          </b-dropdown-item>
        </table-row-options>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import TableRowOptions from '@/components/TableRowOptions.vue';
import lodashMixin from '@/mixins/lodash.mixin';

export default {
  name: 'RecentSelections',
  components: {TableRowOptions},
  mixins: [ lodashMixin ],
  props: {
    user: {
      type: Object,
      required: true
    },
    ensembles: {
      type: Array,
      required: true
    },
    selections: {
      type: Array,
      required: true
    },
    applications: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      items: [],
      fields: [
        { key: 'application.student', label: 'Student' },
        { key: 'ensemble.name', label: 'Ensemble' },
        { key: 'application.instrument.name', label: 'Instrument' },
        { key: 'part', label: 'Part' },
        { key: 'createdAt', label: 'Created', formatter: this.date },
      ]
    }
  },
  computed: {
    filteredItems() {
      if(this.user.groups.includes('NYSSMA_Committee') || this.user.groups.includes('NYSSMA_Chair')) {
        return this.items.filter(selection => this.user.ensembleIds.includes(selection.selectionEnsembleId))
      }
      if(this.user.groups.includes('Teacher')) {
        return this.items.filter(selection => this.applications?.some(application => application.applicationSelectionId === selection.id))
      }
      return this.items
    }
  },
  watch: {
    loading(value) {
      this.items = this.selections.map(selection => ({ ...selection,
            ensemble: this.ensembles.find(ensemble => ensemble.id === selection.selectionEnsembleId),
            application: this.applications.find(application => application.id === selection.selectionApplicationId),
          }
      ))
    }
  },
  mounted() {
    if(this.$can('read', 'all-state-selection') || this.$can('read', 'all-state-selection-acceptance')) {
      this.fields.push({ key: 'row-options', label: '', tdClass: ['align-middle', 'table-row-options'] },)
    }
  },
  methods: {
    date(value) {
      return new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(value))
    }
  }
}
</script>

<style scoped>

</style>
