<template>
  <b-card body-class="h-100">
    <b-card-header class="p-0">
      <b-card-title class="mb-sm-0 mb-1">
        Accepted & Declined
      </b-card-title>
    </b-card-header>
    <b-overlay :show="loading" :opacity="1" variant="white" spinner-small>
      <vue-apex-charts v-if="!loading"
                       type="bar"
                       height="400"
                       :options="options"
                       :series="series"
      />
    </b-overlay>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'ScoredAndSelectedApplicationsCard',
  components: {
    VueApexCharts
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    selections: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      series: [],
      options: {
        colors: ['#212969', '#28c76f', '#ea5455'],
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          },
          radialBar: {
            size: 185,
            hollow: {
              size: '25%',
            },
            track: {
              margin: 15,
            },
            dataLabels: {
              name: {
                fontSize: '2rem',
                fontFamily: 'Montserrat',
              },
              value: {
                fontSize: '1rem',
                fontFamily: 'Montserrat',
              },
              total: {
                show: true,
                fontSize: '1rem',
                label: 'Applications',
                formatter() {
                  return '100%'
                },
              },
            },
          },
        },
        legend: {
          show: false,
          position: 'bottom',
          horizontalAlign: 'center',
        },
        stroke: {
          lineCap: 'round',
        },
        labels: ['Selected', 'Accepted', 'Declined'],
      },
    }
  },
  watch: {
    loading() {
      this.setChartData()
    }
  },
  methods: {
    setChartData() {
      this.series = []

      let selections = []
      if(this.user.groups.includes('NYSSMA_Chair')) {
       selections = this.selections.filter(selection => this.user.ensembleIds.includes(selection.selectionEnsembleId) )
      }
      else {
        selections = this.selections
      }

      const totalSelections = selections.length
      const accepted = selections.filter(selection => selection.accepted === true).length
      const declined = selections.filter(selection => selection.accepted === false).length

      this.series = [{ data: [ totalSelections, accepted, declined ]}]
    }
  }
}
</script>

<style scoped>

</style>
