<template>
  <b-card body-class="h-100">
    <b-card-header class="p-0">
      <b-card-title class="mb-sm-0 mb-1">
        Application Submission Time
      </b-card-title>
      <div>

        <b-button-toolbar>
          <b-button-group>
            <b-dropdown right variant="transparent" size="sm" :text="by.label">
              <b-dropdown-item v-for="option in byOptions" :key="option.value" :value="option.value" @click="by = option">
                {{ option.label }}
              </b-dropdown-item>
            </b-dropdown>

            <b-button variant="transparent" size="sm" @click="showModal = true">
              <b-icon icon="arrows-angle-expand" />
            </b-button>
          </b-button-group>
        </b-button-toolbar>


        <b-modal v-if="!loading" v-model="showModal" size="xl" centered hide-footer>
          <template v-slot:modal-title>
            Application Submission Time
          </template>
          <vue-apex-charts v-if="!loading"
               ref="chart"
               :options="lineOptions"
               :series="computedSeries"
          />
        </b-modal>
      </div>
    </b-card-header>
    <b-overlay :show="loading" :opacity="1" variant="white" spinner-small>
      <vue-apex-charts v-if="!loading"
                       ref="chart"
                       height="450"
                       :options="lineOptions"
                       :series="computedSeries"
      />
    </b-overlay>
  </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'ApplicationSubmissionChart',
  components: {
    VueApexCharts
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    applications: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      by: { label: 'Today', value: 'Today' },
      byOptions: [
        {label: 'Today', value: 'Today'},
        {label: 'Yesterday', value: 'Yesterday'},
        {label: 'Current Week', value: 'CurrentWeek'},
        {label: 'Previous Week', value: 'PreviousWeek'},
        {label: 'Current Month', value: 'CurrentMonth'},
        {label: 'Previous Month', value: 'PreviousMonth'},
        {label: 'All Time', value: 'All'},
      ],
      showModal: false,

      series: [],
      options: {
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        colors: ['#00cfe8', '#212969', '#F1C243', '#28c76f', '#ff9f43', '#ea5455', '#7F3578'],
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: '13px',
                  fontWeight: 900,
                }
              }
            }
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          // categories array for 24-hour period, but the value is am/pm  12-hour time
          categories: Array.from({length: 24}, (v, k) => k).map(hour => {
            const ampm = hour < 12 ? 'am' : 'pm'
            const hour12 = hour % 12
            return `${hour12 === 0 ? 12 : hour12}${ampm}`
          }),
        },
        yaxis: {
          title: {
            text: undefined
          },
        },
        tooltip: {
          x: {},
          y: {}
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
      lineOptions: {
        chart: {
          type: 'line',
          height: 350,
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        colors: ['#212969', '#F1C243', '#28c76f', '#ff9f43', '#ea5455', '#7F3578', '#00cfe8'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          // categories array for 24-hour period, but the value is am/pm  12-hour time
          categories: Array.from({length: 24}, (v, k) => k).map(hour => {
            const ampm = hour < 12 ? 'am' : 'pm'
            const hour12 = hour % 12
            return `${hour12 === 0 ? 12 : hour12}${ampm}`
          }),
        },
        yaxis: {
          title: {
            text: undefined
          },
        },
        tooltip: {
          x: {},
          y: {}
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
        }
      },
    }
  },
  computed: {
    computedSeries() {
      let applications = null


      //if by is 'Today' then use applications from today
      if(this.by.value === 'Today') {
        const today = new Date()
        applications = this.applications.filter(application => {
          const applicationDate = new Date(application.createdAt)
          return applicationDate.toDateString() === today.toDateString()
        })
      }

      //if by is 'Yesterday' then use applications from yesterday
      else if(this.by.value === 'Yesterday') {
        const today = new Date()
        const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
        applications = this.applications.filter(application => {
          const applicationDate = new Date(application.createdAt)
          return applicationDate.toDateString() === yesterday.toDateString()
        })
      }

      //if by is 'CurrentWeek' then use applications from the current week
     else if(this.by.value === 'CurrentWeek') {
        const today = new Date()
        const currentWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay())
        applications = this.applications.filter(application => {
          const applicationDate = new Date(application.createdAt)
          return applicationDate >= currentWeekStart
        })
      }
      //if by is 'PreviousWeek' then use applications from the previous week
      else if(this.by.value === 'PreviousWeek') {
        const today = new Date()
        const previousWeekStart = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 7)
        const previousWeekEnd = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() - 1)
        applications = this.applications.filter(application => {
          const applicationDate = new Date(application.createdAt)
          return applicationDate >= previousWeekStart && applicationDate <= previousWeekEnd
        })
      }
      // if by is 'CurrentMonth' then use applications from the current month
      else if(this.by.value === 'CurrentMonth') {
        const today = new Date()
        const currentMonthStart = new Date(today.getFullYear(), today.getMonth(), 1)
        applications = this.applications.filter(application => {
          const applicationDate = new Date(application.createdAt)
          return applicationDate >= currentMonthStart
        })
      }
      //if by is 'PreviousMonth' then use applications from the previous month
      else if(this.by.value === 'PreviousMonth') {
        const today = new Date()
        const previousMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        const previousMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0)
        applications = this.applications.filter(application => {
          const applicationDate = new Date(application.createdAt)
          return applicationDate >= previousMonthStart && applicationDate <= previousMonthEnd
        })
      }
      else {
       applications = this.applications
     }

      // group applications by createdAt iso-8601 date field by day of the week and hour of the day and count the number of applications in each group sorted by day of the week and hour of the day in 15 minute increments
      const groupedApplications = applications.reduce((acc, application) => {
        const date = new Date(application.createdAt)
        const day = date.getDay()
        const hour = date.getHours() // 0-23
        const key = `${day}-${hour}`
        if(!acc[key]) {
          acc[key] = 0
        }
        acc[key]++
        return acc
      }, {})

      // convert grouped applications object to array of objects with day of the week and hour of the day and number of applications in each group
      const groupedApplicationsArray = Object.keys(groupedApplications).map(key => {
        const [day, hour] = key.split('-')
        return {
          day,
          hour,
          count: groupedApplications[key]
        }
      })

      //sort grouped applications array by day of the week and hour of the day
      groupedApplicationsArray.sort((a, b) => {
        if(a.day < b.day) {
          return -1
        }
        if(a.day > b.day) {
          return 1
        }
        if(a.hour < b.hour) {
          return -1
        }
        if(a.hour > b.hour) {
          return 1
        }
        return 0
      })


      //convert grouped applications into a stacked barchart array that can be passed to the vue-apex-charts component where the category is the hour, the series is the day of the week, and the value is the number of applications in that group
      const seriesOut = [] // array of series objects  {name: 'day', data: [0, 0, 0, 0, 0, 0, 0]} one series object for each day of the week  0-6   0 = Sunday  6 = Saturday
      for(let i = 0; i < 7; i++) { // 7 days of the week
        seriesOut.push({ // create a series object for each day of the week
          name: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][i], // convert day of the week number to day of the week name 0 = Sunday  6 = Saturday
          data: Array.from({length: 24}, (v, k) => k).map(hour => 0) // create a data array for the series object for each hour of the day  0-23
        })
      }

      //for each day, group the number of applications by hour of the day
      groupedApplicationsArray.forEach(group => {
        seriesOut[group.day].data[group.hour] = group.count
      })
      return seriesOut
    }
  },
  watch: {
    computedSeries(data) {
      //if by is 'Today' then show only the series for today
      if(this.by.value === 'Today' && this.applications.length) {
        const today = new Date()
        data.forEach(series => {
          if(series.name !== today.toLocaleDateString('en-US', {weekday: 'long'})) {
            //vue next tick is required to hide the series
            this.$nextTick(() => {
              this.$refs.chart.hideSeries(series.name)
            })
          }
        })
      }
      //if by is 'Yesterday' then show only the series for yesterday
      else if(this.by.value === 'Yesterday' && this.applications.length) {
        const today = new Date()
        const yesterday = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
        data.forEach(series => {
          if(series.name !== yesterday.toLocaleDateString('en-US', {weekday: 'long'})) {
            //vue next tick is required to hide the series
            this.$nextTick(() => {
              this.$refs.chart.hideSeries(series.name)
            })
          }
        })
      }
    }
  },
}
</script>

<style>

</style>
