<template>
  <b-row v-if="alerts.welcome.show || alerts.schools.show" class="pb-1 match-height">
    <b-col v-if="user && alerts.welcome.show">
      <!-- Welcome -->
      <template>
        <b-alert show dismissible variant="primary" class="font-weight-normal match-height" @dismissed="storeAlert('welcome')">
          <h4 class="text-primary"> Welcome {{ user.name.first }}!</h4>
          <p>
            Here are a few ideas on how you can get started.
          </p>
          <ul class="mt-1">
            <li v-if="user.groups.includes('Teacher')">
              Verify your School associations.
            </li>
            <li>
              Check out the <b-link :to="{ name: 'support-getting-started' }"><u>Getting Started</u></b-link> guided tours.
            </li>
            <li>
              Check out the <b-link :to="{ name: 'support-docs' }"><u>Documentation</u></b-link> pages.
            </li>
            <li v-if="user.verified !== true">
              It looks like you haven't verified your account yet, we strongly recommend you do that <b-link :to="{ name: 'account-settings', query: { tab: 'verify'}}"><u>here</u></b-link>.
            </li>
          </ul>
        </b-alert>
      </template>
    </b-col>
    <b-col v-if="user.groups.includes('Teacher') && alerts.schools.show">
      <!-- Schools -->
      <template>
        <b-alert show dismissible variant="primary" class="mb-2 match-height" @dismissed="storeAlert('schools')">
          <b-icon-info-circle/>
          <span class="align-middle ml-50">My Schools</span> <br/>
          <small>
            If any of the schools listed below are incorrect or missing, please
<!--            <b-link :to="{
              name: 'support-tickets',
              params: {
                modal: {
                  show: true,
                  data: {
                     title: 'Incorrect Schools',
                     text: `
                      <p>It appears that one or more of the schools (${user ? user.schools.items.map(item => item.school.name.legal).join(', ') : null}) associated to my account is [incorrect/missing].</p>
                      <p>Please [remove/add] the following schools: __________________</p>
                      `,
                     labels: ['account'],
                     schools: true,
                  }
                 }
              }
            }">
              <u>create a ticket</u>
            </b-link>-->

            <b-link :to="{ name: 'support' }">
              <u>contact us</u>
            </b-link>

            so that it may be corrected.
          </small>

          <b-row v-if="user" class="match-height">
            <b-col v-for="item in user.schools.items" :key="item.id" align-self="center" class="col-12 col-xxxl-6">
              <b-card class="card-statistics mt-1 mb-0" body-class="py-1">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar size="36" variant="light-primary">
                      <font-awesome-icon icon="fa-school" size="lg"></font-awesome-icon>
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <b-spinner v-if="!user" small class="align-middle"></b-spinner>
                    <h6 v-else class="font-weight-bold font-small-3 mb-0">
                      {{ item.school.name.legal }}
                    </h6>
                    <b-card-text class="text-muted font-small-2 mb-0">
                      <template v-if="item.school.district">
                        {{ item.school.district.name.legal }}
                      </template>
                      <template v-else>
                        Private
                      </template>
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-card>
            </b-col>
          </b-row>

        </b-alert >

      </template>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'WelcomeCard',
  props: {
    user: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      alerts: {
        welcome: { show: this.getAlertValue('welcome') },
        schools: { show: this.getAlertValue('schools') }
      }
    }
  },
  methods: {
    storeAlert(key) {
      if(this.alerts[key] !== null) { this.alerts[key] = { show: false } }
      if(!this.$store.getters['storageLocal/isRouteDisabled']('dashboard')) {
        this.$store.dispatch('storageLocal/updateItem', {
          key: 'dashboard',
          subKey: `alert.${key}`,
          value: false
        })
      }
    },
    getAlertValue(key) {
      if(this.$store.getters['storageLocal/isRouteDisabled']('dashboard')) { return true }
      return this.$store.getters['storageLocal/getSubItem']('dashboard', `alert.${key}`, true)
    }
  }
}
</script>

<style scoped>

</style>
