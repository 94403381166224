<template>
  <b-card class="card-statistics">
    <b-media no-body>
      <b-media-aside class="mr-2">
        <b-avatar size="48" variant="light-success">
          <feather-icon size="24" icon="CheckSquareIcon"/>
        </b-avatar>
      </b-media-aside>
      <b-media-body class="my-auto">
        <b-spinner v-if="loading" small class="align-middle"></b-spinner>
        <h4 v-else class="font-weight-bolder mb-0">
          {{ value }}
        </h4>
        <b-card-text class="font-small-3 mb-0">
          Selections
        </b-card-text>
      </b-media-body>
    </b-media>
  </b-card>
</template>

<script>
export default {
  name: 'StatCard',
  props: {
    user: {
      type: Object,
      required: true
    },
    selections: {
      type: Array,
      required: true
    },
    applications: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
  },
  computed: {
    value() {
      if(this.user.groups.includes('NYSSMA_Committee') || this.user.groups.includes('NYSSMA_Chair')) {
        return this.selections.filter(selection => this.user.ensembleIds.includes(selection.selectionEnsembleId)).length
      }
      if(this.user.groups.includes('Teacher')) {
        return this.selections?.filter(selection => this.applications.some(application => application.applicationSelectionId === selection.id)).length
      }
      return this.selections?.length || 0
    },
    maxValue() {
      return this.selections?.length || 0
    }
  }
}
</script>

<style scoped>

</style>
